<template lang="pug">
    v-card(v-if='!isLoading').caseDialog
        v-card-title(class='d-flex justify-center' style='position: relative;') {{$t('ACTIVITY.TITLE')}}
            v-overlay.not-allow(v-show='activityData.completed == true || !canEdit' :absolute="true" opacity='0.03' z-index='999')
            v-card-text
                v-form(v-model='valid' ref='form')  
                    v-text-field.mb-4(v-model='activityData.subject' :label=`$t('ACTIVITY.SUBJECT')` :rules='formRules.nameRule' required='required' large='large' validate-on-blur :readonly='!canEdit')
                    //- v-row(no-gutters)
                    //-     v-chip(class='mr-2 mt-2' :color='activityData.activity_type == e? e.color : ""' v-for='e, index in activity_type_list' :key='e.id' @click='changeType(e)' :readonly='!canEdit')
                    //-         v-icon(left) {{ e.icon }}
                    //-         span {{ e.name }}
                    v-row(no-gutters)
                        v-col(cols='12' :class='typeMenu ? "" : "ellipsis"')
                            v-chip(class='mr-2 mt-2' :color='activityData.activity_type == e? e.color : ""' v-for='e, index in activity_type_list' :key='e.id' @click='changeType(e)')
                                v-icon(left) {{ e.icon }}
                                span {{ e.name }}
                        v-btn.pa-0(@click='changeMenu()' color='primary' text) 
                            v-icon {{typeMenu == true ? "mdi-chevron-up" : "mdi-chevron-down" }}
                            span {{typeMenu == true ? $t('CASE_DETAIL.COLLAPSE') : $t('CASE_DETAIL.EXPAND') }}
                    v-row(no-gutters class='mt-2')
                        v-col(col)
                            .t-black.padding-top-9 {{$t('ACTIVITY.START_TIME')}} :
                        v-col(cols='3')
                            v-menu(ref="menuStart" v-model="startDayMenu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.no-border.height-48(v-model='startDay' readonly solo dense flat v-bind="attrs" v-on="on" :readonly='!canEdit' :change='toDateCheck()')
                                date-picker(v-model='startDay' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                        v-col(col class='d-flex align-center justify-center')
                            p.ma-0.pb-2.pr-2 — 
                        v-col(col)
                            .t-black.padding-top-9 {{$t('ACTIVITY.DUE_TIME')}} :
                        v-col(cols='3')
                            v-menu(ref="menuEnd" v-model="endDayMenu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.no-border.height-48(v-model='endDay' readonly solo dense flat v-bind="attrs" v-on="on" :readonly='!canEdit')
                                date-picker(v-model='endDay' :disabled-date="notBeforeStartDay" :disabled-time="notBeforeStartTime" type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                    v-row(no-gutters)
                        v-col(cols="6")
                            v-select.no-border.height-48(v-model='activityData.priority' solo :items='activityPriorityList' prepend-icon='mdi-flag-variant-outline' item-text='name' item-value='key' :label= `$t('ACTIVITY.PRIORITY')` :readonly='!canEdit')
                        v-col(cols="6")
                            //- v-text-field.no-border.height-48(v-model.number='activityData.progress' type='number' :label= `$t('ACTIVITY.PROGRESS')` large="large" :rules='formRules.progressRules' prepend-icon='mdi-percent-outline' :readonly='!canEdit')
                            //- v-progress-linear(v-model="activityData.progress" :color="activityData.activity_type.color" height="10" stream buffer-value="0" )
                            //-     template(v-slot='value')
                            //-         span {{ Math.ceil(activityData.progress) }}
                            v-slider.pt-2(v-model="activityData.progress" :label=`$t('ACTIVITY.PROGRESS')` :color="activityData.activity_type.color" :track-color="activityData.activity_type.color" :thumb-color="activityData.activity_type.color" thumb-label="always" thumb-size="24")
                    v-textarea.textarea-style.no-border(:readonly='!canEdit' v-model='activityData.remark' :label=`$t('ACTIVITY.REMARK')`, prepend-icon='mdi-calendar-text' large='large')
                    v-combobox.no-border.height-48(:readonly='!canEdit' v-model='activityData.owner' :items='ownerList' item-text='full_name' item-value="id" :loading="isLoading"  prepend-icon='mdi-account' hide-details="auto" :label=`$t('ACTIVITY.OWNER')`)
                    v-combobox.no-border.height-48(:readonly='!canEdit' v-model='activityData.regarding' :items='activityRegardingList' item-text='list_name' item-value="id" :loading="regarding_list_loading" :search-input.sync="searchRegarding" prepend-icon='mdi-link' hide-details="auto" :label=`$t('ACTIVITY.REGARDING')`)
                        template(v-slot:no-data)
                            v-list-item()
                                span {{$t("ACTIVITY.NO_REGARDING")}}
                        template(v-slot:item='data')
                            div.d-flex.ellipsis(:title='data.item.list_type == "case" ? data.item.subject + " [ " + $t("CASE.TITLE")+" ]":data.item.name + " [ " + $t("DEAL.TITLE")+" ]"')
                                .t-black.ml-2.ellipsis(v-if='data.item.list_type == "case"' style='max-width:400px;' ) {{ data.item.subject }}
                                .t-black.ml-2.ellipsis(v-else style='max-width:400px;' ) {{ data.item.name }}
                                .t-black.ml-2 [ {{ data.item.list_type == "case" ? $t('CASE.TITLE') : $t('DEAL.TITLE') }} ]
                        template(v-slot:selection='data')
                            div.d-flex.ellipsis(:title='data.item.list_type == "case" ? data.item.subject + " [ " + $t("CASE.TITLE")+" ]":data.item.name + " [ " + $t("DEAL.TITLE")+" ]"')
                                .t-black.ellipsis(v-if='data.item.list_type == "case"' style='max-width:450px;' ) {{ data.item.subject }}
                                .t-black.ellipsis(v-else-if='data.item.list_type == "oppty"' style='max-width:450px;' ) {{ data.item.name }}
                                .t-black.ml-2(v-if='data.item.list_type == "case"') [ {{ $t('CASE.TITLE') }} ]
                                .t-black.ml-2(v-else-if='data.item.list_type == "oppty"') [ {{ $t('DEAL.TITLE') }} ]
                    v-combobox.no-border.height-48(:readonly='!canEdit' v-model='activityData.activity_contact' :items='activityContactList' item-text='full_name' item-value="id" :loading="contact_list_loading" :search-input.sync="searchContact" prepend-icon='mdi-account' hide-details="auto" :label=`$t('ACTIVITY.CONTACT')`)
                        template(v-slot:no-data)
                            v-list-item()
                                span {{$t("ACTIVITY.NO_CONTACT")}}
                        template(v-slot:item='data')
                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                v-icon(v-if="data.item.image == null") mdi-account
                                img(v-else :src='data.item.image')
                            .t-black {{ data.item.full_name }}
                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                        template(v-slot:selection='data')
                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                    v-combobox.no-border.height-48(:readonly='!canEdit' v-model='activityData.activity_org' :items='activityOrgList' item-text='name' item-value="id" :loading="org_list_loading" :search-input.sync="searchOrg" prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ACTIVITY.ORG')`)
                        template(v-slot:no-data)
                            v-list-item()
                                span {{$t("ACTIVITY.NO_ORG")}}
                        template(v-slot:selection='data')
                            .t-black.ellipsis(:title='data.item.name') {{ data.item.name }}
        v-card-actions.px-2.py-4
            v-row
                v-col.mt-0(cols='4')
                    v-row.mt-0
                        v-checkbox.mt-0.no-border(v-model='activityData.completed' , no-resize )
                        span.mt-1 {{$t('ACTIVITY.MARK_COMPLETED')}}
                v-col(cols='2')
                v-col.pr-1(cols='2')
                    v-btn(v-if='canEdit' :ripple='false' @click='onDelete' width='100%' height='40' color='red' depressed dark) {{$t('GENERAL.DELETE')}}
                v-col.pl-1.pr-1(cols='2')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-1(cols='2')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed :disabled='saving')
                        v-progress-circular(v-if='saving' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')

</template>

<script>
    import Vue from 'vue';
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    import messageDialog from '@/components/Dialog/messageDialog';
    import DatePicker from 'vue2-datepicker';
    // list
    import activityPriorityList from"@/array/the_activityPriorityList";
    import ContactDataService from "@/services/ContactDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import CaseDataService from "@/services/CaseDataService";
    import OpportunityDataService from "@/services/OpportunityDataService";
    import ActivityDataService from '../../../services/ActivityDataService';

    export default Vue.extend({
        props: {
            canEdit: {
                type: Boolean,
                required: true,
            }, 
            activityId: {
                type: String,
                required: true,
            }, 
            activityType: {
                type: Array,
                required: true,
            },
            ownerList: {
                type: Array,
                required: true,
            }, 
            createMession_index: {
                type: Number,
                require: false,
            },
        },
        components: {
            errorDialog,
            messageDialog,
            DatePicker,
        },
        data() {
            return {
                saving:false,
                activityData:null,
                isLoading: false,
                isSearchLoading: false,
                valid: true,
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_TITLE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    dateRule: [(v) => !!v || i18n.t('RULE.RULE_DUE_DATE'),],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case_Contact')],
                    phoneRules: [],
                    emailRules: [
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                    contactNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    progressRules: [
                        (v) => /^([0-9]|[1-9][0-9]|100)$/.test(v) || i18n.t('RULE.RULE_PROGRESS'),
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                    // NEW
                    hhmmRule: [
                        (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || i18n.t('RULE.RULE_HHMM')
                    ]
                },
                comboText: '',
                search: null,
                menu: false,
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: ''
                },
                errorDialog: false,
                errorMessage: '',
                messageDialog:false,
                message:'',
                contactSearch: null,
                user: JSON.parse(window.localStorage.getItem('user')),
                search_parent_case : null,
                startDayMenu: false,
                endDayMenu: false,
                startDay: '',
                endDay: '',
                startDayTime: '',
                endDayTime: '',
                // list
                activity_type_list: [],
                activityPriorityList: activityPriorityList,
                activityContactList:[],
                activityOrgList:[],
                activityCaseList:[],
                activityOpptyList:[],
                activityRegardingList:[],
                searchContact:'',
                searchOrg:'',
                searchCase:'',
                searchOppty:'',
                searchRegarding:'',
                contact_list_loading:false,
                org_list_loading:false,
                case_list_loading:false,
                oppty_list_loading:false,
                regarding_list_loading:false,
                comboInput_case : null,
                comboInput_oppty : null,
                comboInput_regarding :null,
                oringal_completed:null,
                typeMenu:false,
            }
        },
        async created() {
            this.isLoading = true;
            this.regarding_list_loading = true;
            this.activity_type_list = this.activityType;
            await ActivityDataService.getActivityDetail(this.activityId)
            .then((response)=>{
                this.activityData = response.data;
                if(response.data.completed == "COMPLETED"){
                    this.activityData.completed = true;
                    this.oringal_completed = true;
                }else{
                    this.activityData.completed = false;
                    this.oringal_completed = false;
                }
                this.startDay =  this.convertDateTime(response.data.start_date).substr(0, 16);
                this.endDay =  this.convertDateTime(response.data.due_date).substr(0, 16);
                this.startDayTime =  this.convertTime(response.data.start_date);
                this.endDayTime =  this.convertTime(response.data.due_date).substr(0, 10);
                this.activityData.owner = this.ownerList.find(e => e.id == response.data.owner.id);
                if(response.data.activity_contact_id != null){
                    this.activityData.activity_contact.full_name = this.get_contact(response.data.activity_contact)
                }
                let type = this.activityType.find(e => e.id == response.data.activity_type.id);
                if(type){
                    this.activityData.activity_type = type;
                }
                this.activityData.regarding = this.get_regarding(response.data.regarding_case,response.data.regarding_oppty);
                this.changeType(this.activityData.activity_type);
            }).catch((e)=>{
                if(e.toString().includes("status code 404")){
                    this.$emit('emitEditActivityDialog',"404")
                }
            })
            .finally(()=>{
                this.isLoading = false;
                this.regarding_list_loading = false;
            })
        },
        methods: {
            notBeforeStartDay(date) {
                const start = new Date(this.startDay);
                start.setHours(0, 0, 0, 0);
                return date < start;
            },
            notBeforeStartTime(date) {
                const start = new Date(this.startDay).getTime();
                return date.getTime() < start;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.startDayMenu = false;
                    this.endDayMenu = false;
                }
            },
            minDate(){
                let current_date = new Date(this.startDay).getTime();
                let min = current_date;
                return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)+" "+(new Date(min).getHours()).toString().padStart(2,0)+":"+(new Date(min).getMinutes()).toString().padStart(2,0)
            },
            toDateCheck(){
                if(this.startDay){
                    let FD = new Date(this.startDay).getTime();
                    let TD = new Date(this.endDay).getTime();
                    if(this.endDay && (TD-FD)<=0){
                        this.endDay = this.minDate(this.startDay);
                    }
                }
            },
            get_contact(contact){
                if(contact.first_name){
                    return contact.last_name+" "+contact.first_name;
                }
                return contact.last_name;
            },
            get_regarding(regarding_case,regarding_oppty){
                let array = []
                regarding_case.forEach(item => {
                            item.list_type = "case";
                            item.list_name = item.subject;
                            array.push(item);
                        })
                regarding_oppty.forEach(item => {
                            item.list_type = "oppty";
                            item.list_name = item.name;
                            array.push(item);
                        })
                this.activityRegardingList = array;
                if(this.activityRegardingList.length >0 ){
                    return array[0];
                }
                else{
                    return null;
                }
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            changeMenu(){
                this.typeMenu = !this.typeMenu;
            },
            changeType(e){
                this.activityData.activity_type = e;
                this.activity_type_list.map((item,index)=>{
                    if(item == e){
                        this.activity_type_list.splice(index,1)
                        this.activity_type_list.unshift(e)
                    }
                })
            },
            convertTime(time){
                time = new Date (time)
                return time.toLocaleTimeString("en-US", {
                    hour: '2-digit',
                    minute:'2-digit',
                    hour12: false
                });
            },
            convertDateTime(time){
                time = new Date (time)
                return time.toLocaleTimeString("fr-CA", {
                    year:"numeric", month: "2-digit", day:"2-digit"
                }).substr(0,10)+" "+time.toLocaleString('en-US', {hour: '2-digit',minute: '2-digit',hour12: false,});
            },
            async onConfirm() {
                if(!this.canEdit){
                    if(this.oringal_completed != this.activityData.completed)
                    {
                        let data = {
                            id : this.activityData.id,
                            completed : this.activityData.completed == true ? "COMPLETED": "UNCOMPLETED",
                        }
                        ActivityDataService.updateActivityCompleted(data)
                        .then(()=>{
                            this.$emit('emitEditActivityDialog','edit')
                        })
                    }else{
                        this.$emit('emitEditActivityDialog',false)
                    }
                    return
                }
                if(this.validateForm()){
                    this.saving = true;
                    let editData = {
                        id: this.activityData.id,
                        subject: this.activityData.subject,
                        start_date: this.startDay,
                        due_date: this.endDay,
                        completed : this.activityData.completed == true ? "COMPLETED": "UNCOMPLETED",
                        priority : this.activityData.priority,
                        progress : this.activityData.progress,
                        remark: this.activityData.remark,
                        activity_type: this.activityData.activity_type,
                        owner: this.activityData.owner,
                        activity_contact_id: this.activityData.activity_contact == null || this.activityData.activity_contact.id == undefined ? null : this.activityData.activity_contact.id,
                        activity_org_id: this.activityData.activity_org == null || this.activityData.activity_org.id == undefined ? null : this.activityData.activity_org.id,
                        regarding_case:[],
                        regarding_oppty:[]
                    }
                    if(this.activityData.regarding && typeof(this.activityData.regarding) != 'string'){
                        if(this.activityData.regarding.list_type == 'case'){
                            editData.regarding_case = [
                                {
                                    id:this.activityData.regarding.id,
                                    subject:this.activityData.regarding.subject
                                }
                            ]
                            editData.regarding_oppty = [];
                        }else{
                            editData.regarding_oppty = [
                                {
                                    id:this.activityData.regarding.id,
                                    name:this.activityData.regarding.name
                                }
                            ]
                            editData.regarding_case = [];
                        }
                    }
                    ActivityDataService.updateActivity(editData)
                    .then(()=>{
                        this.$emit('emitEditActivityDialog','edit');
                        this.saving = false;
                    })
                    .catch((e)=>{
                        if(e.toString().includes("status code 404")){
                            this.$emit('emitEditActivityDialog',"404")
                        }
                    })
                }
            },
            onCancel() {
                this.$emit('emitEditActivityDialog',false)
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            onDelete(){
                this.showMessageDialog(i18n.t('ACTIVITY.MESSAGE_DELETE'));
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                if(val){
                    ActivityDataService.deleteActivity(this.activityData.id)
                    .then(()=>{
                        if (typeof(this.createMession_index)=="number"){
                            this.$emit('emitEditActivityDialog',this.createMession_index)
                        }else{
                            this.$emit('emitEditActivityDialog','delete')
                        }
                    })
                }
                this.messageDialog = false;
            },
        },
        computed: {
        },
        watch: {
            "activityData.progress":function(){
                this.activityData.progress = Math.ceil(this.activityData.progress)
            },
            async searchOrg(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.org_list_loading && charNum>=2 && charNum==val.length ) {
                        this.org_list_loading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val,true)
                        .then(response => { 
                            this.activityOrgList = response.data; 
                            let num = 0
                            this.activityOrgList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })
                        })
                        .finally(() => (this.org_list_loading = false));
                    }
                    else {
                        this.org_list_loading = false;
                    }
                }
            },
            searchContact(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.contact_list_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.activityContactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_list_loading = false;
                    })
                }
                else{
                    this.activityContactList = [];
                }
            },
            async searchRegarding(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array_case = [];
                let array_oppty = [];
                // search
                if(isEnZhNum && !!val && isString) {
                    this.regarding_list_loading = true;
                    this.comboInput_regarding = val

                    await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.list_type = "case";
                            item.list_name = item.subject;
                            array_case.push(item);
                        })
                        this.activityRegardingList = this.activityRegardingList.concat(array_case)
                    })

                    await OpportunityDataService.list('list', 'all', 0, 1, val, 50, [],'')
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.list_type = "oppty";
                            item.list_name = item.name;
                            array_oppty.push(item);
                        })
                        this.activityRegardingList = this.activityRegardingList.concat(array_oppty)
                    })
                    .finally(() => {
                        this.regarding_list_loading = false;
                    })
                }
                else{
                    this.comboInput_regarding = null
                }
            },
            // async searchCase(val) {
            //     // check user input is en / zh / num
            //     let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            //     let isEnZhNum = false;
            //     pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            //     // check user input data type
            //     let isString; 
            //     typeof(val) == 'string' ? isString = true : isString = false;
            //     // search
            //     if(isEnZhNum && !!val && isString) {
            //         this.case_list_loading = true;
            //         this.comboInput_case = val

            //         await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
            //         .then(response => {
            //             this.activityCaseList = response.data.list
            //         })
            //         .finally(() => {
            //             this.case_list_loading = false;
            //         })
            //     }
            //     else{
            //         this.comboInput_case = null
            //     }
            // },
            // async searchOppty(val) {
            //     // check user input is en / zh / num
            //     let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            //     let isEnZhNum = false;
            //     pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            //     // check user input data type
            //     let isString; 
            //     typeof(val) == 'string' ? isString = true : isString = false;
            //     // search
            //     if(isEnZhNum && !!val && isString) {
            //         this.oppty_list_loading = true;
            //         this.comboInput_oppty = val

            //         await OpportunityDataService.list('list', 'all', 0, 1, val, 50, [],'')
            //         .then(response => {
            //             this.activityOpptyList = response.data.list
            //         })
            //         .finally(() => {
            //             this.oppty_list_loading = false;
            //         })
            //     }
            //     else{
            //         this.comboInput_oppty = null
            //     }
            // }
        },
    });
</script>