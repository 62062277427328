<template lang="pug">
// 編輯客戶
v-card
  v-card-title.d-flex.justify-center {{$t('Edit_COMPANY.TITLE')}}
  v-card-text
    v-form(v-model="editAccount.valid", ref="form")
      v-text-field.mb-4(
        v-model='editAccount.name',
        :label=`$t('Edit_COMPANY.ORG_NAME')`, name='name',
        :rules='formRule.organizationRules', 
        required='required', 
        large='large'
      )      
      v-select.no-border(
        :label=`$t('Edit_COMPANY.TYPE')`,
        v-model="editAccount.organization_type",
        prepend-icon="mdi-domain",
        :items="orgtypeList",
        item-text="name",
        item-value="key",
        flat
      )
      v-select.no-border(
        :label=`$t('Edit_COMPANY.INDUSTRY')`,
        v-model="editAccount.industry",
        prepend-icon="mdi-domain",
        :items="industryList",
        item-text="name",
        item-value="key",
        flat
      )
      v-file-input.no-border(
        v-model="organizationImage",
        prepend-icon="mdi-image",
        :label=`$t('Edit_COMPANY.IMAGE')`,
        accept="image/*",
        @change='onChangePhoto',
        :rules="formRule.photoRule"
      )
      v-text-field.no-border(
        v-model="editAccount.email",
        prepend-icon="mdi-email",
        :rules="formRule.emailRules",
        :label= `$t('GENERAL.MAIL')`,
      )
      v-text-field.no-border(
        v-model="editAccount.website",
        prepend-icon="mdi-earth",
        :rules='formRule.urlRules',
        :label=`$t('Edit_COMPANY.WEB')`,
      )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="editAccount.main_phone",
            :rules="formRule.contentLenRule50",
            prepend-icon="mdi-phone",
            :label=`$t('Edit_COMPANY.PHONE')`,
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="editAccount.fax",
            :rules="formRule.contentLenRule20",
            prepend-icon="mdi-fax",
            :label=`$t('Edit_COMPANY.FAX')`,
          )
      v-text-field.no-border(
        v-model="editAccount.billing_address",
        prepend-icon="mdi-map",
        :label=`$t('Edit_COMPANY.ADDRESS')`,
      )
      v-text-field.no-border(
        v-model="editAccount.employee",
        prepend-icon="mdi-account-group",
        :rules="formRule.employeeRules",
        :label=`$t('Edit_COMPANY.EMPLOYEE')`,
      )
      v-window
        customFieldRequired(ref='CFRequired' DataClass = 'Organization' :editData='this.editAccount.custom_field'  :StageTypeId='null' ).mt-3

  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="success",
          depressed,
          dark
        ) 
          v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.SAVE')}}
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "@/services/OrganizationDataService";
import industryList from "@/array/the_IndustryList";
import orgtypeList from "@/array/the_OrgtypeList";
import i18n from '/common/plugins/vue-i18n.js';
import customFieldRequired from "@/components/Dialog/customFieldRequired";

export default Vue.extend({
  props: {
    editAccount: {
      type: Object,
      required: true,
    },
  },
  components: {
      customFieldRequired,
  },
  data() {
    return {
      editBtn_loading:false,
      organizationList: [],
      organizationImage: null,
      orgphoto:this.editAccount.image,
      userphoto:this.editAccount.owner.usersetting.photo,
      industryList: industryList,
      orgtypeList: orgtypeList,
      formRule: {
        organizationRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
        urlRules: [
          (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
        emailRules: [
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        employeeRules: [(v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
        contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
        contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
      },
      cfValid:true,
    };
  },
  // created(){
	//   OrganizationDataService.getowner()
  //   .then((response)=>{
  //       this.editAccount.ownList = response.data;
  //     });
  // },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateForm(){
      if(this.$refs.form.validate())
        return true;
      else
        return false;
    },
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.cfValid = false;
        return;
      }
      this.editAccount.custom_field = this.$refs.CFRequired.responsedata;
    },
    async onConfirm() {
      if(this.validateForm() && this.editBtn_loading == false)
      {
        if((this.editAccount.custom_field != null && this.editAccount.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
          this.getResponsedata();
        if(this.cfValid == false)
        {
          this.cfValid = true;
          return;
        }
        this.editBtn_loading = true;
        await OrganizationDataService.edit(this.editAccount)
        .then(
            this.editAccount.image = this.orgphoto,
            this.editAccount.owner.usersetting.photo = this.userphoto,
        );
        if (this.organizationImage != null) 
        {
          await OrganizationDataService.image(this.editAccount.id, this.organizationImage)
            .then(response => {
              this.editAccount.image = response.data.image;
          });
        }
        this.editBtn_loading = false;
        this.$emit("emitEditAccountDialog", true);
      }
    },
    onCancel() {
      this.$emit("emitEditAccountDialog", false);
    },
    onChangePhoto(event) {
      if (event != null) 
      {
        // file type
        let n = event.type.indexOf("image");
        if (n == -1) 
        {
          this.fileType = true;
        } 
        else 
        {
          this.fileType = false;
        }

        // photo size
        if (event.size > 5242880) 
        {
          this.photoSize = true;
        } 
        else 
        {
          this.photoSize = false;
        }
      } 
      else 
      {
        this.photoSize = false;
        this.fileType = false;
      }
    },
  }
});
</script>